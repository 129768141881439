<template>
  <div>
    <div
      style="height: 80px; width: 80px; border-radius: 6px; cursor: pointer; padding: 8px 12px; font-size: 10px;"
      :style="{ backgroundColor: backgroundColor }"
      @click="openDetailModal"
    >
      {{ item.number }}
    </div>

    <a-modal v-model="visible" title="产品详情" width="720px" @cancel="visible = false" :footer="null">
      <a-table rowKey="id" size="small" :data-source="item.batch_items" :columns="columns" :pagination="false" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ["item", "searchValue"],
  computed: {
    backgroundColor() {
      if (this.item.id) {
        for (const batchItem of this.item.batch_items) {
          if (batchItem.material_number === this.searchValue || batchItem.material_name === this.searchValue) {
            return "#52c41a";
          }
        }

        if (this.item.total_quantity > 0) {
          return "#108ee9";
        } else {
          return "#faad14";
        }
      } else {
        return "#d9d9d9";
      }
    },
  },
  data() {
    return {
      visible: false,
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
        },
        {
          title: "产品名称",
          dataIndex: "material_name",
        },
        {
          title: "批次编号",
          dataIndex: "number",
        },
        {
          title: "库存数量",
          dataIndex: "remain_quantity",
        },
      ],
    };
  },
  methods: {
    openDetailModal() {
      if (this.item.id && this.item.total_quantity > 0) {
        this.visible = true;
      }
    },
  },
};
</script>
